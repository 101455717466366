import React from "react"
import { Box, Text, Grid } from "theme-ui"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import PostItem from "../components/Blog/PostItem"
import BackLink from "../components/Blog/BackLink"
import { graphql } from "gatsby"
import { PostInterface } from "../types/post"
import { getPosts } from "../utils/post"

export const pageQuery = graphql`
  query CategoryPage($categoryId: ID!) {
    allPrismicPost(
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          categories: { elemMatch: { category: { id: { eq: $categoryId } } } }
        }
      }
    ) {
      edges {
        node {
          url
          data {
            title {
              text
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    data {
                      name
                    }
                  }
                }
              }
            }
            content {
              raw
            }
            date(formatString: "MMM D, YYYY")
            main_image {
              alt
              url
            }
            description {
              text
            }
          }
        }
      }
    }
  }
`

const Category = ({ pageContext: { categoryName, categoryId }, data }) => {
  const posts: PostInterface[] = getPosts(data)

  return (
    <Box>
      <SEO title={`${categoryName} | Blog`} />
      <Layout>
        <PageHeader>
          <Box sx={{ textAlign: "center" }}>
            <Text sx={{ fontSize: 3 }} variant="secondary">
              Blog Category
            </Text>
            <Text sx={{ fontSize: 5 }}>{categoryName}</Text>
          </Box>
        </PageHeader>
        <Box mb={7} mt={4} sx={{ maxWidth: "1100px", mx: "auto" }} px={3}>
          <Box mb={3} px={[2, 2, 3]}>
            <BackLink />
          </Box>
          {posts.length <= 0 ? (
            <Text mt={4} mb={6} sx={{ textAlign: "center", fontSize: 5 }}>
              No Blog Posts found for this category
            </Text>
          ) : (
            posts.map(post => <PostItem post={post} />)
          )}
        </Box>
      </Layout>
    </Box>
  )
}

export default Category
